<template>
  <div>
    <b-overlay
      :show="Over"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <!-- form -->
      <validation-observer ref="simpleRules2">
        <b-card>
          <h3>
            ตั้งค่าแนะนำเพื่อน
          </h3>
          <b-form>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <label>เทิร์นทีต้องทำ ( เท่า )</label>
                  <validation-provider
                    #default="{ errors }"
                    name="turn"
                    rules="required"
                  >
                    <b-form-input
                      v-model="data.turn"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกเทิร์นทีต้องทำ' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>ถอนได้สูงสุด (เท่า)</label>
                  <validation-provider
                    #default="{ errors }"
                    name="maxwith"
                    rules="required"
                  >
                    <b-form-input
                      v-model="data.maxwith"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกถอนได้สูงสุด' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group>
                  <label>โบนัสที่ได้รับจากเพื่อน ( % )</label>
                  <validation-provider
                    #default="{ errors }"
                    name="percent"
                    rules="required"
                  >
                    <b-form-input
                      v-model="data.percent"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0]? 'กรุณากรอกโบนัสที่ได้รับจากเพื่อน' : '' }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="gradient-success"
                  type="submit"
                  @click.prevent="validationFormUpdate"
                >
                  <i class="fas fa-download" /> บันทึกข้อมูล
                </b-button>
              </b-col>
              <!-- <b-col cols="12">
            <b-button
              variant="gradient-primary"
              type="submit"
              @click.prevent="validationForm"
            >
              <i class="fas fa-download" /> แก้ไขข้อมูล
            </b-button>
          </b-col> -->
            </b-row>
          </b-form>
        </b-card>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
  // BFormSelect,
  // BButtonGroup,
  BOverlay, BIconController,
} from 'bootstrap-vue'
import { required, email, min } from '@validations'
// import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // BFormSelect,
    // BButtonGroup,
    // AppCollapse,
    // AppCollapseItem,
    // vSelect,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      Over: false,
      emailValue: '',
      name: '',
      required,
      email,
      min,
      isActive: false,
      isActive2: false,
      isActive3: false,
      UpdateisActive: false,
      UpdateisActive2: false,
      UpdateisActive3: false,
      data: {
        turn: null,
        maxwith: null,
        percent: null,
      },
      DataUpdate: {
        Username: '',
        Min: '',
        Max: '',
        MaxPerMatch: '',
        CasinoTableLimit: '',
        CompanyKey: '',
        ServerId: '',
        Status: '',
      },
      optionsLimit:
            [
              { value: 1, text: 'ต่ำสุด' },
              { value: 2, text: 'ปานกลาง' },
              { value: 3, text: 'สูงสุด' },
              { value: 4, text: 'VIP (ทั้งหมด)' },
            ],
      AgentList: [],
      AgentId: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      MarketList: [],
      SboList: [],
    }
  },
  mounted() {
    this.GetData()
  },
  methods: {
    GetData() {
      this.$http
        .get('/SettingInvite/index')
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    validationFormUpdate() {
      this.$refs.simpleRules2.validate().then(success => {
        if (success) {
          this.Over = true
          this.$http
            .post('/SettingInvite/Update', this.data)
            .then(() => {
              this.Over = false
              this.Success('บันทึกข้อมูลสำเร็จ')
            })
            .catch(() => {
              this.Over = false
              this.Error('บันทึกข้อมูลไม่สำเร็จ')
            })
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    Error(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>
.active {
  background-color: #077a0d !important;
  color: #ffffff !important;
  border: 1px solid #077a0d !important;
}
.active2 {
  background-color: #e20000 !important;
  color: #ffffff !important;
  border: 1px solid #e20000 !important;
}

.active3 {
  background-color: #858585 !important;
  color: #ffffff !important;
  border: 1px solid #858585 !important;
}

</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
